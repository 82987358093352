import revive_payload_client_mvkTMawgq5 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VrmVPS91n6 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_r9zvoOsP88 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_S1Cs7Dopi5 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EDgOm1MAPe from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_A7cu2i2UlB from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QIt7xsBzYu from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_OKrP299uJn from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.4.0__ioredi_2c240ec19f7ef984018f58a4b93870cf/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_H9EN4Vsi2m from "/build/node_modules/.pnpm/@nuxt+icon@1.7.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@20.16.11_sass@1.79_c4b9337be3129104f0e377e5fbdb638d/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_YV0z0q3BWw from "/build/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@type_9a5ff4dff935bfc358ca30fe981de609/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/build/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/build/.nuxt/element-plus-injection.plugin.mjs";
import vant_lazyload_plugin_W13KNvxip6 from "/build/.nuxt/vant-lazyload.plugin.mjs";
import plugin_liyViRA2ax from "/build/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/build/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/build/.nuxt/unocss.mjs";
import ssr_plugin_yDy8djlHP9 from "/build/node_modules/.pnpm/@vueuse+nuxt@11.1.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._d0685f64b8289e3625f97d1bf57773c9/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_21AFKAreRR from "/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0__ba675ca1e1a14dafeea2796d32dc553c/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import deivce_Irsa0E2qZA from "/build/plugins/deivce.ts";
import fetch_14DdzPiXUB from "/build/plugins/fetch.ts";
export default [
  revive_payload_client_mvkTMawgq5,
  unhead_VrmVPS91n6,
  router_r9zvoOsP88,
  payload_client_S1Cs7Dopi5,
  navigation_repaint_client_EDgOm1MAPe,
  check_outdated_build_client_A7cu2i2UlB,
  chunk_reload_client_QIt7xsBzYu,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OKrP299uJn,
  plugin_H9EN4Vsi2m,
  plugin_YV0z0q3BWw,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  vant_lazyload_plugin_W13KNvxip6,
  plugin_liyViRA2ax,
  plugin_wy0B721ODc,
  unocss_MzCDxu9LMj,
  ssr_plugin_yDy8djlHP9,
  plugin_21AFKAreRR,
  deivce_Irsa0E2qZA,
  fetch_14DdzPiXUB
]